import { LoadableComponent } from '@loadable/component';
import { logError } from 'fergy-core-react-logging';
import React from 'react';
import { ContentDocumentFieldsFragment } from '../../../__generated__/graphql-client-types';
import { ConstructHelper } from '../../../helpers/construct/construct.helper';
import { Content, ContentComponent, ContentComponentProps, TypedContent, TypedContentOptions } from '../../../types/construct.types';
import { contentHandlers } from '../handlers/construct-handlers';

/**
 * Contains options for a renderer function passed from caller. Options are divided by the content type, so options for
 * a type may be in an object keyed by the content type name.
 */
export class ContentComponentHelper<T extends TypedContent> {
	readonly content: T;
	readonly contentId: string;

	/**
	 * This is the type@majorVersion of the content object schema.
	 */
	readonly schemaType: string;

	constructor(contentDocument: ContentDocumentFieldsFragment) {
		const helper = new ConstructHelper(contentDocument);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		this.content = helper.getContent() as T;
		this.contentId = contentDocument.id;
		this.schemaType = helper.getSchemaType();
	}

	render(content: Content | undefined, index?: number, options?: TypedContentOptions): JSX.Element | null {
		if (!isTypedContent(content)) {
			return null;
		}
		const Component = this.getContentComponent(content, this.schemaType);
		if (!Component) {
			this.unhandledContent(content, index);
			return null;
		}

		return <Component key={index} content={content} index={index} renderer={this} options={options} />;
	}

	private getContentComponent(
		content: TypedContent,
		schemaType: string
	): LoadableComponent<ContentComponentProps<TypedContent>> | ContentComponent<TypedContent> | undefined {
		return (
			// schema-specific renderer
			contentHandlers[`${content.type}:${schemaType}:${content.kind}`] ??
			// kind-specific renderer
			contentHandlers[`${content.type}:${content.kind}`] ??
			// default renderer
			contentHandlers[`${content.type}:default`]
		);
	}

	private unhandledContent(content: TypedContent, index: number | undefined) {
		logError(
			new Error(
				`Unhandled content (type: ${content.type}, kind: ${content.kind ?? '""'}, index ${index}): ${JSON.stringify(
					content,
					null,
					2
				)}`
			)
		);
	}
}

const isTypedContent = (content: Content | undefined): content is TypedContent =>
	content !== null && !Array.isArray(content) && typeof content === 'object';
